<template>
  <!-- Information Card 2 -->
    <div
      class="col-content h-full"
      style="background-image: var(--degrade-rosa, linear-gradient(50deg, #921882 0%, #6648F6 100%)); border-radius: 15px;"
    >
      <div class="card-content" style="padding: 15px;">
        <h6>Indique e Ganhe: Cashback Garantido! <svg class="action-icon" style="margin-left: 8px;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#FFFFFF"
                viewBox="0 0 576 512">
                <path
                  d="M400 255.4V240 208c0-8.8-7.2-16-16-16H352 336 289.5c-50.9 0-93.9 33.5-108.3 79.6c-3.3-9.4-5.2-19.8-5.2-31.6c0-61.9 50.1-112 112-112h48 16 32c8.8 0 16-7.2 16-16V80 64.6L506 160 400 255.4zM336 240h16v48c0 17.7 14.3 32 32 32h3.7c7.9 0 15.5-2.9 21.4-8.2l139-125.1c7.6-6.8 11.9-16.5 11.9-26.7s-4.3-19.9-11.9-26.7L409.9 8.9C403.5 3.2 395.3 0 386.7 0C367.5 0 352 15.5 352 34.7V80H336 304 288c-88.4 0-160 71.6-160 160c0 60.4 34.6 99.1 63.9 120.9c5.9 4.4 11.5 8.1 16.7 11.2c4.4 2.7 8.5 4.9 11.9 6.6c3.4 1.7 6.2 3 8.2 3.9c2.2 1 4.6 1.4 7.1 1.4h2.5c9.8 0 17.8-8 17.8-17.8c0-7.8-5.3-14.7-11.6-19.5l0 0c-.4-.3-.7-.5-1.1-.8c-1.7-1.1-3.4-2.5-5-4.1c-.8-.8-1.7-1.6-2.5-2.6s-1.6-1.9-2.4-2.9c-1.8-2.5-3.5-5.3-5-8.5c-2.6-6-4.3-13.3-4.3-22.4c0-36.1 29.3-65.5 65.5-65.5H304h32zM72 32C32.2 32 0 64.2 0 104V440c0 39.8 32.2 72 72 72H408c39.8 0 72-32.2 72-72V376c0-13.3-10.7-24-24-24s-24 10.7-24 24v64c0 13.3-10.7 24-24 24H72c-13.3 0-24-10.7-24-24V104c0-13.3 10.7-24 24-24h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H72z" />
              </svg></h6>
        
        <span style="color: aliceblue; font-size: small;">
          Compartilhe os melhores eventos e ganhe recompensas incríveis! Indique agora e acumule cashback.
        </span>
      </div>
      <!-- <div class="card-footer">
        <a-button ghost shape="round">
          <span>Read More</span>
          <a-icon class="ml-5" type="arrow-right" />
        </a-button>
      </div> -->
    </div>
  <!-- / Information Card 2 -->
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
