<template>
    <div>
      <!-- cards -->
      <div class="cards">
        <div class="content">
          <div class="slider">
                <div
                class="story"
                v-for="(data, index) in cards"
                :key="index"
                >
                <div>
                    <div class="cards">
                    <img src="https://m.media-amazon.com/images/I/81h6WFnSGgL._AC_UL480_FMwebp_QL65_.jpg" alt="" />
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- MODAL cards -->
      <modal name="story-modal" height="100%" :adaptive="true">
        <!-- CONTEUDO DO MODAL -->
        <div class="close-button" @click="closeStoryModal">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" stroke="#ffffff" height="1.5em" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/></svg></div>
  
            <div v-if="selectedStory">
          <div v-html="selectedStory.content"></div>
        </div>
      </modal>
    </div>
  </template>
  
  
  <script>
  import Vue from "vue";
  import VModal from "vue-js-modal";
  Vue.use(VModal);
  
  // Data for cards and producer information
  const cards = [
    // Story 1
    {
      id: "1",
      thumbnail: "https://img.youtube.com/vi/KZuDYXNKr6A/default.jpg",
      content:
        '<iframe width="353" height="627" src="https://www.youtube.com/embed/KZuDYXNKr6A" title="Pare de andar com idi0t4s" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
    },
    // Story 2
    {
      id: "2",
      thumbnail: "https://img.youtube.com/vi/pJSXMSpmRvI/default.jpg",
      content:
        '<iframe width="353" height="627" src="https://www.youtube.com/embed/pJSXMSpmRvI" title="Vai trabalhar ou ficar chorando?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
    },
    // Story 3
    {
      id: "3",
      thumbnail: "https://img.youtube.com/vi/4724TKwAOGc/default.jpg",
      content:
        '<iframe width="353" height="627" src="https://www.youtube.com/embed/4724TKwAOGc" title="Pare de andar com idi0t4s" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
    },
    {
      id: "3",
      thumbnail: "https://img.youtube.com/vi/4724TKwAOGc/default.jpg",
      content:
        '<iframe width="353" height="627" src="https://www.youtube.com/embed/4724TKwAOGc" title="Pare de andar com idi0t4s" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
    },
    {
      id: "3",
      thumbnail: "https://img.youtube.com/vi/4724TKwAOGc/default.jpg",
      content:
        '<iframe width="353" height="627" src="https://www.youtube.com/embed/4724TKwAOGc" title="Pare de andar com idi0t4s" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
    },
  ];
  
  const producerInfoDash = [
    { linkedMembers: { value: 150 } },
    { newMembers: { value: 12 } },
    { myProducts: { value: 4 } },
    { revenueGenerated: { value: 53 } },
  ];
  
  // Placeholder arrays
  const metas = [];
  const countContent = [{ value: 0 }];
  const countIndicate = [{ value: 0 }];
  const badgeInfo = [
    {
      name: "Aprendiz",
      info: "Parabéns! Toda grande história sempre tem um começo e você está no lugar certo.",
      badge: "",
    },
  ];
  
  export default {
    data() {
      return {
        // Data properties
        isProductor: localStorage.getItem("userType") === "producer",
        countContent,
        countIndicate,
        allContent: [],
        topContent: [],
        metas,
        cards,
        selectedStory: null,
        selectedContent: null,
        heightValue: "80%",
        widthValue: "80%",
        modal: "",
        producerInfoDash,
        badgeInfo,
      };
    },
    mounted() {
      // Initializations on component mount
      this.fetchAllContent();
      this.fetchCountContent();
  
      const screenWidth = window.innerWidth || document.documentElement.clientWidth;
      const screenHeight = window.innerHeight || document.documentElement.clientHeight;
  
      console.log(screenWidth);
      console.log(screenHeight);
  
      // Atualiza as dimensões em todos os objetos do array
      this.cards.forEach((story) => {
        story.content = story.content.replace('width="353"', `width="${screenWidth}"`);
        story.content = story.content.replace('height="627"', `height="${screenHeight}"`);
      });
    },
    methods: {
      closeStoryModal() {
      this.$modal.hide("story-modal");
      this.selectedStory = null; // Limpar a história selecionada
  
    },
  
      // Method to identify top products
      identifyTopProducts() {
        if (this.allContent.length > 0) {
          this.topContent = this.allContent.slice(0, 3);
        }
      },
  
      // Miscellaneous methods
      emptySpace() {
        const maxSpaces = 4;
        return Math.max(maxSpaces - this.metas.length, 0);
      },
      openStoryModal(storie) {
        this.selectedStory = storie;
        this.$modal.show("story-modal");
      },
    },
    computed: {
      date() {
        // Placeholder for computed property
      },
    },
  };
  </script>
  
  
  
  <style lang="scss" scoped>
  /* cards */
  .cards {
    margin-top: 10px;
    margin-bottom: -5px;
    padding-bottom: 12px;
    margin-bottom: 20px;
  cursor: pointer;
  img {
    height: 250px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    object-fit: cover; /* Adicionada esta propriedade para estilo cover */

  }
  }
  
  .content {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    cursor: grab;
  }
  .content::-webkit-scrollbar {
    overflow-x: auto;
    white-space: nowrap;
  }
  .content::-webkit-scrollbar {
    width: 6px;
  }
  
  .content::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 3px;
  }
  
  .content::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .slider {
    display: flex;
  }
  
  .story {
    flex: 0 0 auto;
    margin-right: 10px;
    cursor: pointer;
  }
  
  @media (max-width: 992px) {
    #CardTopContent {
      margin-bottom: 5px;
    }
  }
  
  /* METAS */
  .newGoal {
    height: 315px;
    width: 100%;
    background: #0d0f14;
  
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  
    border: 2px #fff;
    border-style: dashed;
    border-radius: 12px;
  
    .text {
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.553px;
    }
  }
  
  /* Estilos para o botão de fechar */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 18px;
    color: #fff;
  }
  </style>
  
  