<template>
  <div>
    <a-row class="header-search" style="margin-top: 8px;">
      <a-input class="search" placeholder="  Pesquisar" size="large" v-model="searchTerm"
        style="height: 50px !important;">
        <template slot="prefix">
          <svg style="margin-left: 2px;" xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" height="1.2em"
            viewBox="0 0 512 512">
            <path
              d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
          </svg>
        </template>
      </a-input>
      <div class="filter"></div>
    </a-row>

    <a-row :gutter="24" type="flex" align="stretch">
      <a-col :span="24" :lg="24">
        <CardInfo2 class="mb-24 h-auto"></CardInfo2>
      </a-col>
    </a-row>

    <a-row class="offers-grid" type="flex" :gutter="24">
      <a-col v-for="offer in filteredOffers" :key="offer.id" style="margin-bottom: 24px">
        <!-- CARDS OFERTAS -->
        <router-link :to="`/pages/offer/members/listDetails/${offer.id}/${offer.type}`">
          <div class="offersCards">
            <div class="img">
              <img :src="offer.img" alt="" />
            </div>
            <div class="info">
              <!--  <a-tag style="margin-bottom: 10px;" :color="color">{{ translateType(offer.type) }}</a-tag> -->
              <a-card-meta :title="offer.name" :description="truncateDescription(offer.description, 70)"></a-card-meta>
            </div>
          </div>
        </router-link>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import WidgetCarrossel from "../../../components/Widgets/WidgetCarrossel";
import WidgetOffers from "../../../components/Widgets/WidgetOffers.vue";
import CardInfo2 from "../../../components/Cards/CardInfo2";


export default {
  components: {
    WidgetCarrossel,
    WidgetOffers,
    CardInfo2,
  },
  data() {
    return {
      offers: [],
      searchTerm: "",
      isLoadindContents: true,
      producersToFilter: [
        "14e541b8-3234-4f78-98e8-77d55d0dc28f",
        "df45a765-aee8-449c-b916-6df8796533ea",
        "e3733673-7602-4bdd-8d9d-95510da0b35d",
        "680f9d88-6233-4855-b3b3-6477513e3053",
        "de282524-effc-4bfe-8858-9cca3fd5ad48",
        "a05391a1-3c12-49a5-b7ad-7b1ede522faf",
        "8d2d3c3e-787c-4a4c-bbc8-036265a8db27",
        "733375e4-9eb3-4983-a95d-35d687d78cfc",
        "34ae31dc-64d0-48c2-b397-fe1fd2c84970",
        "81fc56d2-6343-46ed-8835-0e095c92d82f",
        "1e1a359a-32a1-4a5d-a62c-9b2a75627d6c",
        "1fd7b46e-ae2c-42b9-87f9-db0fd79c91b2",
        "4fa3b7a2-01f0-4ac0-818a-b51abf09c23a",
        "235c803c-8c08-49ab-8160-a47cf645408a",
        "e1a15f95-8a55-43e6-971d-f27199cfa03e",
        "0295cd2a-f241-4894-b210-d805448cf1d3",
        "20203f31-2ec7-4864-a7f5-d345169b569c",
        "14e541b8-3234-4f78-98e8-77d55d0dc28f",
        "83b267e2-064f-4cb3-8325-6e64c626e9a8",
        "66b650ea-1166-433e-b0c4-096a9f5520f7",
        "0a634df1-48da-4aff-af6f-49aa365a16dd",
        "ca6b3623-67b6-4677-9a29-289a42e64a18",
      ],
    };
  },
  computed: {
    filteredOffers() {
      return this.offers
        .filter((offer) => offer.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
        .filter((offer) => !this.producersToFilter.includes(offer.id));
    },
  },
  methods: {
    getOffers() {
      this.$api.dataService
        .getData("member/purchasable")
        .then((res) => {
          if (res.events || res.products || res.courses) {
            this.offers = [
              ...this.mapFilteredOffers(res.events, "Event"),
              ...this.mapFilteredOffers(res.products, "Product"),
              ...this.mapFilteredOffers(res.courses, "Course"),
            ];
          }
          this.isLoadindContents = false;
        })
        .catch((error) => {
          console.error("Erro ao buscar ofertas:", error);
        });
    },

    mapFilteredOffers(offers, type) {
      if (!offers) return [];
      const filtered = offers
        .filter((offer) => !this.producersToFilter.includes(offer.id))
        .map((offer) => ({
          id: offer.id,
          name: offer.name || offer.title,
          description: offer.description,
          img: offer.banner ? offer.banner['1x1'] : "https://lp-js-libs.s3.sa-east-1.amazonaws.com/assets/Plataforma/sem-imagem.jpg",
          type: type,
        }));
      console.log('Filtered Offers:', filtered);
      return filtered;
    },

    truncateDescription(description, maxLength) {
      if (description.length <= maxLength) {
        return description;
      } else {
        return description.slice(0, maxLength) + "...";
      }
    },

    translateType(type) {
      switch (type) {

        case 'Course':
          this.color = '#607e01';
          return 'CURSO'
        case 'Event':
          this.color = '#1ad8ff';
          return 'EVENTO'
        default:
          return 'CONTEÚDO'
      }
    },
  },

  mounted() {
    this.getOffers();
  },
};
</script>

<style lang="scss">
.header-search {
  margin-bottom: 1rem;
}

.ant-input {
  height: 50px;
  border-radius: 65px;
  border: 0;
}

.offers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.offersCards {
  width: 100%;
  height: 400px;

  background: #141921;
  border-radius: 12px;
  box-shadow: -1px 1px 10px -3px rgba(0, 0, 0, 0.137);
  cursor: pointer;
  overflow: hidden;

  .img {
    overflow: hidden;
    height: 60%;
    /* Altura máxima da imagem em relação à altura do card */
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info {
    padding: 1rem 1rem 1rem 1rem;

    .ant-card-meta-title {
      font-weight: 700;
    }

    .ant-card-meta-title,
    .ant-card-meta-description {
      color: #fff;
    }
  }

  .skeleton-loading {
    width: 100%;
    height: 150px;
    /* Ajuste a altura conforme necessário */
    background: linear-gradient(90deg, #0e1117 25%, #10161d 50%, #1a232e 75%);
    background-size: 200% 100%;
    animation: loading 3.5s infinite;
    border-radius: 10px;
  }

  @keyframes loading {
    0% {
      background-position: 200% 0;
    }

    100% {
      background-position: -200% 0;
    }
  }
}
</style>

